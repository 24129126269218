import React from "react"
import clsx from "clsx"
import { navigate, Link } from "gatsby"

// @MaterialUI
import { makeStyles } from "@material-ui/core/styles"
import Avatar from "@material-ui/core/Avatar"
import CssBaseline from "@material-ui/core/CssBaseline"
import Drawer from "@material-ui/core/Drawer"
import Box from "@material-ui/core/Box"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
// import Badge from '@material-ui/core/Badge';
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import MenuIcon from "@material-ui/icons/Menu"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import { mainListItems, secondaryListItems } from "../components/listItems"

// @Firebase
import firebase from "gatsby-plugin-firebase"

// CSS
import "../assets/sass/layout.scss"

// @Local
import ReduxTesting from "../components/_misc/reduxTesting"
// @Local Auth.
import { logout, isLoggedIn, getUser, getUserExtra } from "../utils/auth"

const ITEM_HEIGHT = 48

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://vecol.com.co/">
        Vecol
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

export default function Layout(props) {
  const user = getUser()
  const userExtra = getUserExtra()
  const { displayName, photoURL } = user
  const { ocupation } = userExtra

  // if (!isLoggedIn()) {
  //   navigate(`/app/login`)
  // }

  let { children, section, sectionLink } = props
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const openOpts = Boolean(anchorEl)
  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }
  const optionTopClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          {section && sectionLink && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={() => {
                  navigate(sectionLink)
                }}
              >
                <ArrowBackIcon style={{ color: "#FFF" }} />
              </IconButton>
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                {section}
              </Typography>
            </div>
          )}
          {section && !sectionLink && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                {section}
              </Typography>
            </div>
          )}
          <Typography
            component="h3"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          ></Typography>
          {user && (
            <div className="wrapper-top-profile">
              <div className="wrapper-top-profile-info">
                <p className={classes.avatarName}>{displayName}</p>
                <p className={classes.avatarPosition}>{ocupation}</p>
              </div>
              <Avatar
                className={classes.logo}
                alt={displayName}
                src={
                  photoURL ? photoURL : require("../images/logo200Alone.png")
                }
              />
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={optionTopClick}
              >
                <MoreVertIcon style={{ color: "#FFF" }} />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={openOpts}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "20ch",
                  },
                }}
              >
                <MenuItem
                  key={"profile"}
                  onClick={event => {
                    event.preventDefault()
                    navigate(`/app/perfil`)
                  }}
                >
                  Mis datos
                </MenuItem>
                <MenuItem
                  key={"logout"}
                  onClick={event => {
                    event.preventDefault()
                    logout(firebase).then(() => navigate(`/app/login`))
                  }}
                >
                  Cerrar sesión
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        {isLoggedIn() ? (
          <div className="vecol-logo-wrapper">
            <Divider />
            <ListItem>
              <ListItemIcon>
                <Link to="/app/home">
                  <Avatar
                    alt={"Vecol"}
                    src={require("../images/logo200Alone.png")}
                    className={clsx(
                      classes.avatarLogo && open && classes.avatar
                    )}
                  />
                </Link>
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={classes.avatarLogoText}
                primary={"Intranet Vecol"}
              />
            </ListItem>
          </div>
        ) : null}
        <Divider />
        <List>{mainListItems}</List>
        <Divider />
        <List>{secondaryListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container
          maxWidth="lg"
          className={clsx(classes.container, "dtRootMainContainer")}
        >
          <div style={{ display: "none" }}>
            <ReduxTesting />
          </div>
          <Grid container spacing={3}>
            {children}
          </Grid>
        </Container>
        <Box pt={4} className={"wrapperCopyright"}>
          <Copyright />
        </Box>
      </main>
    </div>
  )
}

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  avatarName: {
    fontSize: 13,
    margin: 0,
    fontWeight: 700,
    color: "#FFF",
  },
  avatarPosition: {
    fontSize: 12,
    margin: 0,
    fontWeight: 300,
    color: "#FFF",
  },
  btn: {
    fontSize: 10,
    margin: "10px 0",
    height: 20,
    textTransform: "none",
  },
  avatarLogo: {
    transition: "all .5s cubic-bezier(.2,1,.22,1)",
  },
  avatarLogoText: {
    fontWeight: "bold",
    textAlign: "center",
    textTransform: "uppercase",
  },
  avatar: {
    transition: "all .5s cubic-bezier(.2,1,.22,1)",
    height: 60,
    width: 60,
    borderRadius: "50%",
    margin: "0 5px",
  },
  logo: {
    margin: "0 5px",
    border: "solid",
    borderWidth: 1,
    borderColor: "#FFF",
  },
}))
