import React from "react"

// @Material UI
import Button from "@material-ui/core/Button"

import { connect } from "react-redux"
import { blancoAction } from "../../actions/blancoAction"
import { rojoAction } from "../../actions/rojoAction"

function Component(props) {
  const onClick = () => {
    if (props.st === "text") {
      props.blancoAction()
    } else {
      props.rojoAction()
    }
  }

  return (
    <div>
      <Button variant="contained" color="primary" onClick={onClick}>
        {props.st === "btn" ? "Back to Text" : "Back to Button"}
      </Button>
    </div>
  )
}

const mapStateToProps = state => ({
  ...state,
})

const mapDispatchToProps = dispatch => ({
  blancoAction: () => dispatch(blancoAction),
  rojoAction: () => dispatch(rojoAction),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
