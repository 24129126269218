import React from "react"
import { Link } from "gatsby"

// @Material UI
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Tooltip from "@material-ui/core/Tooltip"
import DashboardIcon from "@material-ui/icons/Home"
import BookIcon from "@material-ui/icons/Bookmarks"
import WorkIcon from "@material-ui/icons/Work"
import EventIcon from "@material-ui/icons/Event"
import PhotoAlbumIcon from "@material-ui/icons/PhotoAlbum"
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"

export const mainListItems = (
  <div className="main-link-items-menu">
    <Link to="/app/home">
      <Tooltip title="Inicio" placement="right" arrow>
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Inicio" />
        </ListItem>
      </Tooltip>
    </Link>
    <Link to="/app/actualidad">
      <Tooltip title="Actualidad" placement="right" arrow>
        <ListItem button>
          <ListItemIcon>
            <BookIcon />
          </ListItemIcon>
          <ListItemText primary="Actualidad" />
        </ListItem>
      </Tooltip>
    </Link>
    <Link to="/app/vacantes">
      <Tooltip title="Vacantes" placement="right" arrow>
        <ListItem button>
          <ListItemIcon>
            <WorkIcon />
          </ListItemIcon>
          <ListItemText primary="Vacantes" />
        </ListItem>
      </Tooltip>
    </Link>
    <Link to="/app/eventos">
      <Tooltip title="Eventos" placement="right" arrow>
        <ListItem button>
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
          <ListItemText primary="Eventos" />
        </ListItem>
      </Tooltip>
    </Link>
    <Link to="/app/multimedia">
      <Tooltip title="Multimedia" placement="right" arrow>
        <ListItem button>
          <ListItemIcon>
            <PhotoAlbumIcon />
          </ListItemIcon>
          <ListItemText primary="Multimedia" />
        </ListItem>
      </Tooltip>
    </Link>
  </div>
)

export const secondaryListItems = (
  <div className="secondary-link-items-menu">
    <Link to="/contenido/pagina/5eefa344353c3056dc26912b">
      <Tooltip title="Gestión de documentos" placement="right" arrow>
        <ListItem button>
          <ListItemIcon>
            <AssignmentIndIcon style={{ color: "#365eab" }} />
          </ListItemIcon>
          <ListItemText primary="Gestión de documentos" />
        </ListItem>
      </Tooltip>
    </Link>
  </div>
)
