import React from "react"
import Button from "@material-ui/core/Button"

function Payload() {
  return <Button variant="contained">BTN</Button>
}

export const blancoAction = {
  type: "hgr0221",
  st: "btn",
  payload: <Payload />,
}
